import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { FaRotateLeft } from "react-icons/fa6";
import { FaHome } from "react-icons/fa";

import { useAppContext } from "../../context/AppContext";
import styles from "./styles.module.css";
import Rank from "../../components/Rank/Rank";

interface IScore {
  id: string;
  event_id: string;
  player_name: string;
  score: number;
  campo1: string;
  campo2: string;
  campo3: string;
}
interface IProps {
  nextStep: (value: number) => void;
}

const Ranking: React.FC<IProps> = ({ nextStep }) => {
  const { username, event, handleGetQuestions, handleInstantiateScore } =
    useAppContext();
  function setNextStep(step: number) {
    if (step === 2 && !username) step = 1;
    nextStep(step);
  }

  const [scores, setScores] = useState<IScore[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/pocket/score/${event?.id}`
        );
        setScores(response.data.data);
        console.warn(response.data.data);
      } catch (e) {
        console.warn("Could not load Player Rankings! :(");
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function playAgain() {
    if (!username) setNextStep(1);
    else {
      await handleGetQuestions();
      await handleInstantiateScore();
      setNextStep(2);
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {scores && <Rank scores={scores} />}
        <div id="end" className={styles.foster}>
          <Button onClick={() => playAgain()} className={styles.btn}>
            Jogar Novamente <FaRotateLeft />
          </Button>
          <Button
            size="lg"
            onClick={() => setNextStep(1)}
            className={styles.btn}
          >
            Voltar ao inicio <FaHome />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Ranking;
