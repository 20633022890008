import { Header } from "../../components/Header";
import styles from "./styles.module.css";
import imgNotFound from "../../assets/images/not-found.png";

const HomeInit: React.FC = () => {
  return (
    <main className={styles.main}>
      <Header></Header>

      <div className={styles.container}>
        <div className={styles.content}>
          <img src={imgNotFound} className={styles.notFound} alt="notFound" />
          <h1>Evento não encontrado</h1>
          <h2>Use pocket.aexp.com.br/pindoevento</h2>
          <h3>Ou Procure o administrador do evento</h3>
        </div>
      </div>
    </main>
  );
};

export default HomeInit;
