import { BrowserRouter, Routes, Route } from "react-router-dom";
import Pocket from "./views/Pocket";
import HomeInit from "./views/HomeInit";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<HomeInit />} />
        <Route index path="/:pin" element={<Pocket />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
