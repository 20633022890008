import React from "react";
import { FaTrophy } from "react-icons/fa6";

import { useAppContext } from "../../context/AppContext";
import styles from "./styles.module.css";
import medal from "../../assets/images/bronze.svg";

interface IScore {
  id: string;
  event_id: string;
  player_name: string;
  score: number;
  campo1: string;
  campo2: string;
  campo3: string;
}


interface IProps {
  scores: IScore[];
}

const colorTrophy = ["#daa520", "#c0c0c0", "#cd7f32"];

const Rank: React.FC<IProps> = ({ scores }) => {
  const { event } = useAppContext();
  return (
    <div className={styles.rank}>
      <div className={styles.scores}>
        <table className={styles.tabela}>
          <thead className={styles.header}>
            <tr>
              <th>Posição</th>
              <th>Name</th>
              {event?.config.campo1 !== "" && <th>{event?.config.campo1}</th>}
              {event?.config.campo2 !== "" && <th>{event?.config.campo2}</th>}
              {event?.config.campo3 !== "" && <th>{event?.config.campo3}</th>}
              <th>Pontos</th>
            </tr>
          </thead>
          <tbody className={styles.body}>
            {scores
              .sort((a, b) => b.score - a.score)
              .map((score, index) => {
                index = index + 1;
                return (
                  <tr
                    key={index}
                    className={`${styles.line} ${
                      index <= 3 ? styles[`podium_${index}`] : ""
                    }`}
                  >
                    <td>
                      {index <= 3 ? (
                        <FaTrophy
                          size={50}
                          color={colorTrophy[index - 1]}
                          className={styles.trophy}
                        />
                      ) : (
                        <img src={medal} alt="medalha" />
                      )}
                      {/* <FontAwesomeIcon icon={faTrophy} size='lg'/> */}
                    </td>
                    <td>{score.player_name}</td>
                    {event?.config.campo1 !== "" && <td>{score.campo1}</td>}
                    {event?.config.campo2 !== "" && <td>{score.campo2}</td>}
                    {event?.config.campo3 !== "" && <td>{score.campo3}</td>}
                    <td>{score.score}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Rank;
