import axios from "axios";
import {
  ReactNode,
  createContext,
  useState,
  useContext,
  SetStateAction,
  useEffect,
} from "react";

interface ConfigProps {
  campo1: string;
  campo2: string;
  campo3: string;
}
interface IQuestion {
  id: string;
  description: string;
  question_point: number;
  answer_1: string;
  answer_2: string;
  answer_3: string;
  answer_4: string;
  correct_answer: number;
  is_active: boolean;
}

interface IEvent {
  id: string;
  pin: string;
  name: string;
  description: string;
  created_by: string;
  client_id: string;
  created_at: string;
  expire_at: string;
  config: ConfigProps;
}

interface IGameState {
  points: number;
  questionAnswered: number;
}

interface AppContextProviderProps {
  children: ReactNode;
}
interface AppContextData {
  username: string;
  scoreId: string;
  event: IEvent | undefined;
  gameState: IGameState;
  questions: IQuestion[] | undefined;
  campo1: string;
  campo2: string;
  campo3: string;

  setGameState: (gameState: SetStateAction<IGameState>) => void;
  setUsername: (username: string) => void;
  setEvent: (event: IEvent) => void;
  handleGetQuestions: () => Promise<boolean>;
  handleInstantiateScore: () => Promise<boolean>;
  handleUpdateScore: () => Promise<boolean>;
  handleDeleteScore: () => Promise<boolean>;
  setCampo1: (value: SetStateAction<string>) => void;
  setCampo2: (value: SetStateAction<string>) => void;
  setCampo3: (value: SetStateAction<string>) => void;
}
export const AppContext = createContext({} as AppContextData);

export function AppProvider({ children }: AppContextProviderProps) {
  //evento
  const [event, setEvent] = useState<IEvent | undefined>(undefined);

  //questões
  const [questions, setQuestions] = useState<IQuestion[] | undefined>(
    undefined
  );

  //pontuação
  const [scoreId, setScoreId] = useState<string>("");
  const [gameState, setGameState] = useState<IGameState>({
    points: 0,
    questionAnswered: 0,
  });

  //identificação
  const [username, setUsername] = useState("");
  const [campo1, setCampo1] = useState("");
  const [campo2, setCampo2] = useState("");
  const [campo3, setCampo3] = useState("");

  useEffect(() => {
    console.log(username);
  }, [username]);

  const handleGetQuestions = async () => {
    setGameState({
      points: 0,
      questionAnswered: 0,
    });
    const response = await axios
      .get(`${process.env.REACT_APP_API}/pocket/question`)
      .then((response) => {
        setQuestions(response.data.data);
        console.log(response.data.data);
        return true;
      })
      .catch((error) => {
        console.log("erro ao obter as questões: ", error.message);
        return false;
      });

    return response;
  };

  const handleInstantiateScore = async () => {
    console.log({
      name: username,
      score: 0,
      campo1: campo1,
      campo2: "",
      campo3: "",
    });
    return await axios
      .post(`${process.env.REACT_APP_API}/pocket/score/${event?.id}`, {
        name: username,
        score: 0,
        campo1: campo1,
        campo2: "",
        campo3: "",
      })
      .then((response) => {
        const { id } = response.data.data;
        setScoreId(id);
        return true;
      })
      .catch((error) => {
        return false;
      });
  };

  const handleUpdateScore = async () => {
    return await axios
      .put(`${process.env.REACT_APP_API}/pocket/score/${scoreId}`, {
        score: gameState.points,
        campo1: campo1,
        campo2: campo1,
        campo3: campo1,
      })
      .then(() => {
        return true;
      })
      .catch((error) => {
        return false;
      });
  };
  const handleDeleteScore = async () => {
    return await axios
      .delete(`${process.env.REACT_APP_API}/pocket/score/${scoreId}`)
      .then(() => {
        setScoreId("");
        return true;
      })
      .catch((error) => {
        console.log(error.message);
        return false;
      });
  };
  return (
    <AppContext.Provider
      value={{
        event,
        username,
        gameState,
        questions,
        scoreId,
        campo1,
        campo2,
        campo3,
        setGameState,
        setUsername,
        setEvent,
        handleGetQuestions,
        handleInstantiateScore,
        handleUpdateScore,
        handleDeleteScore,
        setCampo1,
        setCampo2,
        setCampo3,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useAppContext = (): AppContextData => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext deve ser usado dentro de um AppProvider");
  }
  return context;
};
