import { useState } from "react";
import { Form, Input } from "reactstrap";
import { FaCrown } from "react-icons/fa6";

import { useAppContext } from "../../context/AppContext";
import styles from "./styles.module.css";

interface IProps {
  nextStep: (value: number) => void;
}

const Home: React.FC<IProps> = ({ nextStep }) => {
  const {
    username,
    event,
    campo1,
    campo2,
    campo3,
    setUsername,
    handleGetQuestions,
    handleInstantiateScore,
    setCampo1,
    setCampo2,
    setCampo3,
  } = useAppContext();

  const [playerNameIsValid, setPlayerNameIsValid] = useState(true);

  const [campo1IsValid, setCampo1IsValid] = useState(true);

  const [campo2IsValid, setCampo2IsValid] = useState(true);

  const [campo3IsValid, setCampo3IsValid] = useState(true);

  async function setNextStep(step: number) {
    nextStep(step);
  }

  function checkPlayerName() {
    // Verifica se o username não contém números
    const containsNoNumbers = /^[^\d]+$/.test(username);

    // Verifica se o username tem pelo menos três caracteres
    const isLongEnough = username.length >= 3;

    const isValid = containsNoNumbers && isLongEnough;

    setPlayerNameIsValid(isValid);
    return isValid;
  }

  const checkInputs = () => {
    let isValid = true;

    if (event?.config.campo1) {
      setCampo1IsValid(!!campo1);
      isValid = !!campo1;
    }
    if (event?.config.campo2) {
      setCampo2IsValid(!!campo2);
      isValid = !!campo2;
    }
    if (event?.config.campo3) {
      setCampo3IsValid(!!campo3);
      isValid = !!campo3;
    }
    return isValid;
  };

  async function handleStartGame(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!checkPlayerName() || !checkInputs()) {
      return;
    }

    const getQuestions = await handleGetQuestions();
    if (getQuestions) {
      const getScore = await handleInstantiateScore();
      if (getScore) {
        nextStep(2);
      } else console.log("falha ao preparar ao score");
    } else console.log("falha ao preparar as questões");
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Remove qualquer dígito (número) do valor
    const sanitizedValue = event.target.value.replace(/\d/g, "");
    setUsername(sanitizedValue);
  };
  return (
    <div className={styles.content}>
      <Form className={styles.form} onSubmit={handleStartGame}>
        <h1>Vamos Jogar?</h1>
        <Input
          type="text"
          placeholder="Identifique-se"
          value={username}
          onChange={handleNameChange}
        ></Input>
        {!playerNameIsValid && (
          <div>
            <p className="p-0 m-0  fw-bolder text-danger">Verifique o nome:</p>
            <p className="p-0 m-0 text-danger mx-3">
              • O nome deve conter de 3 a 50 caracteres.
            </p>
            <p className="p-0 m-0 text-danger mx-3">
              • Somente letras e números.
            </p>
          </div>
        )}

        {event?.config.campo1 && (
          <Input
            type="text"
            name="name"
            onBlur={checkInputs}
            placeholder={event?.config.campo1}
            onInput={(evento) => {
              const target = evento.target as HTMLInputElement;
              setCampo1(target.value);
            }}
          ></Input>
        )}
        {event?.config.campo1 && !campo1IsValid && (
          <div className="row">
            <div className="mb-2">
              <p className="fw-bolder text-danger">
                O conteúdo de {event?.config.campo1} não pode estar vazio
              </p>
            </div>
          </div>
        )}
        {event?.config.campo2 !== "" ? (
          <Input
            type="text"
            name="name"
            onBlur={() => checkInputs()}
            placeholder={event?.config.campo2}
            onInput={(evento) => {
              const target = evento.target as HTMLInputElement;
              setCampo2(target.value);
            }}
          ></Input>
        ) : null}
        {event?.config.campo2 && !campo2IsValid ? (
          <div className="row">
            <div className="mb-2">
              <p className="fw-bolder text-danger">
                O conteúdo de {event?.config.campo2} não pode estar vazio
              </p>
            </div>
          </div>
        ) : null}
        {event?.config.campo3 !== "" ? (
          <Input
            type="text"
            name="name"
            placeholder={event?.config.campo3}
            onBlur={() => checkInputs()}
            onInput={(evento) => {
              const target = evento.target as HTMLInputElement;
              setCampo3(target.value);
            }}
          ></Input>
        ) : null}
        {event?.config.campo3 && !campo3IsValid ? (
          <div className="row">
            <div className="mb-2">
              <p className="fw-bolder text-danger">
                O conteúdo de {event?.config.campo3} não pode estar vazio
              </p>
            </div>
          </div>
        ) : null}
        <button className={styles.btn} type="submit">
          Jogar
        </button>
        <button
          onClick={() => setNextStep(4)}
          className={`${styles.highScore} ${styles.btn}`}
        >
          Melhores Pontuações <FaCrown className="ml-2" />
        </button>
      </Form>
    </div>
  );
};

export default Home;
