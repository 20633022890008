import { useState } from "react";

import Home from "../../views/Home/Home";
import Game from "../../views/Game/Game";
import EndGame from "../../views/EndGame/EndGame";
import Ranking from "../../views/Ranking/Ranking";

interface IConfig {
  campo1: string;
  campo2: string;
  campo3: string;
}

interface IEvent {
  id: string;
  pin: string;
  name: string;
  description: string;
  created_by: string;
  client_id: string;
  created_at: string;
  expire_at: string;
  config: IConfig;
}

interface IProps {
  event: IEvent;
}

const StepSelector: React.FC<IProps> = () => {
  const [formStep, setFormStep] = useState(1);

  const nextStep = (step: number) => {
    setFormStep(step);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {(() => {
        switch (formStep) {
          case 1:
            return <Home nextStep={nextStep} />;
          case 2:
            return <Game nextStep={nextStep} />;
          case 3:
            return <EndGame nextStep={nextStep} />;
          case 4:
            return <Ranking nextStep={nextStep} />;
          default:
            return null;
        }
      })()}
    </div>
  );
};

export default StepSelector;
