import styles from "./styles.module.css";
import logo from "../../assets/images/logo.png";
import logoGame from "../../assets/images/logo_game.png";

export const Header = () => {
  return (
    <nav className={styles.nav}>
      <img src={logo} alt="Logo" />
      <img src={logoGame} alt="LogoGame" />
    </nav>
  );
};
