import { FaRankingStar, FaRotateLeft } from "react-icons/fa6";
import { Button } from "reactstrap";

import { useAppContext } from "../../context/AppContext";
import styles from "./styles.module.css";

interface IProps {
  nextStep: (value: number) => void;
}

const EndGame: React.FC<IProps> = ({ nextStep }) => {
  const {
    username,
    gameState,
    handleGetQuestions,
    handleDeleteScore,
    handleInstantiateScore,
    handleUpdateScore,
  } = useAppContext();

  function setNextStep(step: number) {
    nextStep(step);
  }

  async function saveHighScore() {
    handleUpdateScore();
    setNextStep(4);
  }

  async function playAgain() {
    if (!username) setNextStep(1);
    else {
      await handleGetQuestions();
      await handleDeleteScore();
      await handleInstantiateScore();
      setNextStep(2);
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.finalScore}>
          <FaRankingStar /> {gameState.points} Pontos <FaRankingStar />
        </h1>
        <Button
          className={styles.btn}
          id="saveScoreBtn"
          type="button"
          onMouseDown={async () => await saveHighScore()}
        >
          Salvar
        </Button>
        <Button onClick={() => playAgain()} className={styles.btn}>
          Jogar Novamente <FaRotateLeft />
        </Button>
      </div>
    </div>
  );
};

export default EndGame;
