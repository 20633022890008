import { useEffect, useState, useRef } from "react";
import { useAppContext } from "../../context/AppContext";
import { Progress, Button } from "reactstrap";

import styles from "./styles.module.css";
import { toast } from "react-toastify";

interface IQuestion {
  id: string;
  description: string;
  question_point: number;
  answer_1: string;
  answer_2: string;
  answer_3: string;
  answer_4: string;
  correct_answer: number;
  is_active: boolean;
}

interface IProps {
  nextStep: (value: number) => void;
}
const Game: React.FC<IProps> = ({ nextStep }) => {
  const gameForm = useRef<HTMLDivElement>(null);
  const { gameState, questions, setGameState, handleUpdateScore } =
    useAppContext();

  const [question, setQuestion] = useState<IQuestion | null>(null);

  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(true);
  const [confirmAnswer, setConfirmAnswer] = useState(false);
  const [progress, setProgress] = useState(1);
  const [btnNext, setBtnNext] = useState(true);

  useEffect(() => {
    getNewQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    scrollToTop();
  }, [question]);

  function scrollToTop() {
    if (gameForm.current) {
      gameForm.current.scrollTop = 0;
      gameForm.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  function getNewQuestion() {
    if (questions) {
      setQuestion(questions[gameState.questionAnswered]);
      setSelectedAnswer(null);
      setIsAnswerCorrect(false);
      setConfirmAnswer(false);
    }
  }

  const handleAnswerSelect = (answerIndex: number) => {
    if (confirmAnswer) {
      return;
    }
    const isCorrect = answerIndex === question?.correct_answer;
    setIsAnswerCorrect(isCorrect);
    setSelectedAnswer(answerIndex);
  };

  const answerClassName = (index: number) => {
    if (selectedAnswer) {
      if (index === selectedAnswer && !confirmAnswer) {
        return styles.selected;
      } else if (isAnswerCorrect && index === question?.correct_answer) {
        return styles.hit;
      } else if (!isAnswerCorrect && index === selectedAnswer) {
        return styles.miss;
      } else if (confirmAnswer && index === question?.correct_answer) {
        return styles.hit;
      } else if (confirmAnswer && index === selectedAnswer) {
        return styles.miss;
      } else {
        return "";
      }
    }
  };

  async function play() {
    if (!confirmAnswer) {
      if (!selectedAnswer) {
        toast.error("Selecione uma alternativa primeiro!");
        return;
      }
      setConfirmAnswer(true);
      if (isAnswerCorrect) {
        toast.success("✅ Resposta correta!", {
          onOpen: () => setBtnNext(false),
          onClose: () => setBtnNext(true),
        });
        if (question) {
          setGameState((prevState) => ({
            ...prevState,
            points: prevState.points + question?.question_point,
          }));
        }
      } else {
        toast.error("❌ Resposta incorreta!", {
          onOpen: () => setBtnNext(false),
          onClose: () => setBtnNext(true),
        });
      }
      setGameState((prevState) => ({
        ...prevState,
        questionAnswered: prevState.questionAnswered + 1,
      }));
      return;
    }

    if (gameState.questionAnswered >= 10) {
      nextStep(3);
    } else {
      await handleUpdateScore();
      getNewQuestion();
      setProgress(gameState.questionAnswered + 1);
    }
  }

  return (
    <div className={styles.content}>
      <div ref={gameForm} className={styles.gameForm}>
        <div className={styles.hud}>
          <div className={styles.progress}>
            <p className="text-center">{progress} de 10</p>
            <Progress
              max={10}
              value={progress}
              color="success"
              className={styles.progressBar}
            />
          </div>
          <div className={styles.points}>
            <p>Pontos</p>
            <p>{gameState.points}</p>
          </div>
        </div>
        {question && (
          <div>
            <div className={styles.question}>
              <p className="text">{question.description}</p>
            </div>
            <div
              className={`${styles.box} ${answerClassName(1)}`}
              onClick={() => handleAnswerSelect(1)}
            >
              <span className={styles.choiceText}>{question.answer_1}</span>
            </div>
            <div
              className={`${styles.box} ${answerClassName(2)}`}
              onClick={() => handleAnswerSelect(2)}
            >
              <span className={styles.choiceText}>{question.answer_2}</span>
            </div>
            {question.answer_3 !== "" && (
              <div
                className={`${styles.box} ${answerClassName(3)}`}
                onClick={() => handleAnswerSelect(3)}
              >
                <span className={styles.choiceText}>{question.answer_3}</span>
              </div>
            )}
            {question.answer_4 !== "" && (
              <div
                className={`${styles.box} ${answerClassName(4)}`}
                onClick={() => handleAnswerSelect(4)}
              >
                <span className={styles.choiceText}>{question.answer_4}</span>
              </div>
            )}
          </div>
        )}
        <footer className={styles.footer}>
          {selectedAnswer && btnNext && (
            <Button
              color="success"
              size="lg"
              className={styles.btn}
              onClick={() => play()}
            >
              {confirmAnswer ? "Avançar" : "Confirmar"}
            </Button>
          )}
        </footer>
      </div>
    </div>
  );
};

export default Game;
